<template>
  <div class="home">
    <div class="swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="item in bannerList" :key="item.id">
          <img :src="item.imageUrl" class="banner" />
        </div>
        <div class="placeholder" v-if="!bannerList.length"></div>
      </div>
      <div class="swiper-pagination"></div>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>

    <div class="inner">
      <!-- 关于我们 -->
      <div class="aboutUs">
        <div class="title">关于我们</div>
        <div class="info">
          <div class="left">
            <img :src="aboutUs.img" alt="" class="bannerImg" />
          </div>
          <div class="right" v-html="aboutUs.content" />
        </div>
      </div>
      <!-- 动态 -->
      <div class="dynamics">
        <div class="title">基金动态</div>
        <div class="info">
          <div class="left">
            <div class="info__title">
              {{ dynamics.title }}
            </div>
            <div class="info__date">{{ dynamics.createTime }}</div>
            <div class="info__btn" @click="handleJumpDetail(dynamics)">
              立即查看
            </div>
          </div>
          <div class="right" v-if="dynamics.img">
            <img :src="dynamics.img" class="img" />
          </div>
        </div>
        <div class="list">
          <div class="list__block" v-for="item in dynamicsList" :key="item.id">
            <div class="list__title">{{ item.title }}</div>
            <div class="list__introduce">{{ item.introduction }}</div>
            <div class="list__date">
              <span>{{ item.createTime }}</span><img src="../../assets/home/arrow.png" alt class="list__img" @click="handleJumpDetail(item)" />
            </div>
          </div>
        </div>
      </div>
      <!-- 合作伙伴 -->
      <div class="cooperate">
        <div class="title">合作伙伴</div>
        <div class="cooperate-html" v-html="partners.content"></div>
        <!-- <img src="../../assets/home/cooperate.png" class="cooperate__img" /> -->
      </div>
    </div>
    <!-- SideBar -->
    <SideBar />
  </div>
</template>

<script>
import 'swiper/swiper-bundle.min.css'
import Swiper, { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper'
import router from '@/router'
import SideBar from '@/components/SideBar.vue'
import { getBannerListAPI, getRichListAPI, getEssaySortListAPI } from '@/api'

export default {
  name: 'Home',
  data() {
    return {
      bannerList: [],
      aboutUs: {},
      app: {},
      race: {},
      brand: {},
      strategy: {},
      dynamics: {},
      dynamicsList: [],
      partners: {},
    }
  },
  components: {
    SideBar,
  },
  created() {
    this.getBannerList()
    this.getRichList()
    this.getArticleList()
  },
  mounted() {
    console.log(123123)
    window.scrollTo(0, 0)
    new Swiper('.swiper', {
      speed: 600,
      spaceBetween: 100,
      autoplay: {
        delay: 3000,
      },
      modules: [Navigation, Pagination, Autoplay],
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
  },
  methods: {
    handleJumpDetail(item) {
      console.log(item)
      const path = `/news`
      sessionStorage.setItem('newsDetail', JSON.stringify(item))
      router.push(path)
    },
    async getBannerList() {
      const data = await getBannerListAPI()
      this.bannerList = data
    },
    async getRichList() {
      const { id = '1' } = this.$store.state.activeItem
      const data = await getRichListAPI(id)
      const [aboutUs, partners] = data
      this.aboutUs = aboutUs
      this.partners = partners
    },
    async getArticleList() {
      const data = await getEssaySortListAPI({ id: 19 })
      const newData = data.slice(0, 4)
      console.log('newData')
      console.log(newData)
      const dynamics = newData.shift()
      this.dynamics = dynamics
      this.dynamicsList = newData
    },
  },
  computed: {},
}
</script>

<style scoped lang="less">
.aboutUs .right {
  line-height: 26px;
}
.home {
  margin-top: -0.92rem;
  .banner {
    width: 100%;
    height: 100%;
  }
  .placeholder {
    height: 11rem;
  }
}

.inner {
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
  // padding: 0.74rem 1.1rem 1.03rem;
  padding: 0 1.1rem 1.03rem;
  .title {
    font-size: 0.32rem;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #333333;
    line-height: 0.38rem;
    margin-top: 1rem;
  }
  .aboutUs {
    .info {
      margin-top: 0.67rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      .left {
        margin-right: 1rem;
        width: 50%;
      }
      .right {
        padding: 0;
        width: 50%;
      }
      .bannerImg {
        width: 100%;
        height: 100%;
      }
    }
  }
  .dynamics {
    .info {
      width: 100%;
      height: 3.61rem;
      margin-top: 0.67rem;
      background: linear-gradient(
        267deg,
        #f6f8fa 0%,
        rgba(246, 248, 250, 0) 100%
      );
      display: flex;
      justify-content: space-between;
      .left {
        margin: 0.69rem 1.45rem;
        .info__title {
          width: 8rem;
          font-size: 0.24rem;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #333333;
          line-height: 0.28rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .info__date {
          font-size: 0.18rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 0.19rem;
          margin: 0.55rem 0;
        }
        .info__btn {
          cursor: pointer;
          width: 1.37rem;
          height: 0.5rem;
          line-height: 0.5rem;
          background: #ea3939;
          font-size: 0.19rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          text-align: center;
        }
      }
      .right {
        .img {
          width: 5.44rem;
          height: 3.61rem;
        }
      }
    }
    .list {
      background: #f7f9fb;
      padding: 0.69rem 0;
      display: flex;
      .list__block {
        width: 22%;
        border-right: 1px solid #d9d9d9;
        padding: 0.11rem 1rem;
      }
      .list__block:last-child {
        border-right: none;
      }
      .list__title {
        width: 100%;
        font-size: 0.21rem;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .list__introduce {
        font-size: 0.17rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        margin-top: 0.07rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .list__date {
        font-size: 0.2rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        margin-top: 0.32rem;
      }
      .list__img {
        cursor: pointer;
        width: 0.27rem;
        height: 0.27rem;
        vertical-align: middle;
        margin-left: 0.13rem;
      }
    }
  }
  .cooperate {
    .cooperate__img {
      margin-top: 0.61rem;
    }
    .cooperate-html {
      margin-top: 20px;
      /deep/ img {
        // width: 254px;
        // height: 136px;
        //width: 3.387rem;
        //height: 1.81rem;
      }
    }
  }
}
</style>
