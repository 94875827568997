import Vue from 'vue';
import Vuex from 'vuex';
import { getMenuAPI, getCompanyInfoAPI } from '@/api';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    navUrl: '/',
    activeItem: {},
    companyInfo: {},
    menus: [],
  },
  mutations: {
    setNavUrl (state, url) {
      state.navUrl = url;
    },
    setActiveItem (state, item) {
      state.activeItem = item;
    },
    setMenu (state, data) {
      state.menus = data;
    },
    setCompanyInfo (state, data) {
      state.companyInfo = data;
    },
  },
  actions: {
    async setMenuAction ({ commit }) {
      const data = await getMenuAPI();
      sessionStorage.setItem('menus', JSON.stringify(data))
      commit('setMenu', data);
      const obj = data.find((item) => item.path == sessionStorage.getItem('curPath'))
      if (obj) {
        sessionStorage.setItem('ACTIVEITEM', JSON.stringify(obj))
        commit('setActiveItem', obj)
      }
    },
    async getCompanyInfo ({ commit }) {
      const data = await getCompanyInfoAPI();
      const [address, ICP, copyright, qrcCode, phone, ios, android, ICP2] = data;
      commit('setCompanyInfo', {
        address: address.description,
        ICP: ICP.description,
        copyright: copyright.description,
        qrcCode: qrcCode.img,
        iosQrcCode: ios.img,
        androidQrcCode: android.img,
        phone: phone.description,
        ICP2: ICP2.description
      });
    },
  },
  modules: {},
});
